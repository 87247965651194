/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
/* eslint camelcase: 0 */
import { Dataset } from '@superset-ui/chart-controls';
import { t, SupersetClient, QueryFormData } from '@superset-ui/core';
import { Dispatch } from 'redux';
import {
  addDangerToast,
  toastActions,
} from 'src/components/MessageToasts/actions';
import { Slice } from 'src/types/Chart';
import { SaveActionType } from 'src/explore/types';
import { TCustomEvent, TEvent } from 'src/exploreV2/types';

const FAVESTAR_BASE_URL = '/superset/favstar/slice';

export const UPDATE_FORM_DATA_BY_DATASOURCE = 'UPDATE_FORM_DATA_BY_DATASOURCE';
export function updateFormDataByDatasource(
  prevDatasource: Dataset,
  newDatasource: Dataset,
) {
  return {
    type: UPDATE_FORM_DATA_BY_DATASOURCE,
    prevDatasource,
    newDatasource,
  };
}

export const POST_DATASOURCE_STARTED = 'POST_DATASOURCE_STARTED';
export const FETCH_DATASOURCE_SUCCEEDED = 'FETCH_DATASOURCE_SUCCEEDED';
export function fetchDatasourceSucceeded() {
  return { type: FETCH_DATASOURCE_SUCCEEDED };
}

export const FETCH_DATASOURCES_STARTED = 'FETCH_DATASOURCES_STARTED';
export function fetchDatasourcesStarted() {
  return { type: FETCH_DATASOURCES_STARTED };
}

export const FETCH_DATASOURCES_SUCCEEDED = 'FETCH_DATASOURCES_SUCCEEDED';
export function fetchDatasourcesSucceeded() {
  return { type: FETCH_DATASOURCES_SUCCEEDED };
}

export const TOGGLE_FAVE_STAR = 'TOGGLE_FAVE_STAR';
export function toggleFaveStar(isStarred: boolean) {
  return { type: TOGGLE_FAVE_STAR, isStarred };
}

export const FETCH_FAVE_STAR = 'FETCH_FAVE_STAR';
export function fetchFaveStar(sliceId: string) {
  return function (dispatch: Dispatch) {
    SupersetClient.get({
      endpoint: `${FAVESTAR_BASE_URL}/${sliceId}/count/`,
    }).then(({ json }) => {
      if (json.count > 0) {
        dispatch(toggleFaveStar(true));
      }
    });
  };
}

export const SAVE_FAVE_STAR = 'SAVE_FAVE_STAR';
export function saveFaveStar(sliceId: string, isStarred: boolean) {
  return function (dispatch: Dispatch) {
    const urlSuffix = isStarred ? 'unselect' : 'select';
    SupersetClient.get({
      endpoint: `${FAVESTAR_BASE_URL}/${sliceId}/${urlSuffix}/`,
    })
      .then(() => dispatch(toggleFaveStar(!isStarred)))
      .catch(() => {
        dispatch(
          addDangerToast(t('An error occurred while starring this chart')),
        );
      });
  };
}

export const SET_FIELD_VALUE = 'SET_FIELD_VALUE';
export function setControlValue(
  controlName: string,
  value: any,
  validationErrors?: any[],
) {
  return { type: SET_FIELD_VALUE, controlName, value, validationErrors };
}

export const SET_CHART_VERSION = 'SET_CHART_VERSION';
export const setChartVersion = (version: number) => ({
  type: SET_CHART_VERSION,
  payload: version,
});

export const SET_MERGE_IDENTITY_THRESHOLD = 'SET_MERGE_IDENTITY_THRESHOLD';
export const setMergeIdentityThreshold = (threshold: any) => ({
  type: SET_MERGE_IDENTITY_THRESHOLD,
  payload: threshold,
});

export const TOGGLE_IDENTITY_MERGE = 'TOGGLE_IDENTITY_MERGE';
export const setToggleIdentityMerge = (payload: boolean) => ({
  type: TOGGLE_IDENTITY_MERGE,
  payload,
});

export const IS_LEFT_PANE_UPDATED = 'IS_LEFT_PANE_UPDATED';
export const setIsLeftPaneUpdated = (isLeftPane: boolean) => ({
  type: IS_LEFT_PANE_UPDATED,
  payload: { isLeftPane },
});

export const SHOULD_RENEW_SELECTED_ROWS = 'SHOULD_RENEW_SELECTED_ROWS';
export const setShouldRenewSelectedRows = (
  shouldRenewSelectedRows: boolean,
) => ({
  type: SHOULD_RENEW_SELECTED_ROWS,
  payload: { shouldRenewSelectedRows },
});

export const SET_MERGE_IDENTITY = 'SET_MERGE_IDENTITY';
export function setMergeIdentity(payload: any) {
  return { type: SET_MERGE_IDENTITY, payload };
}

export const SET_CUSTOM_EVENT_METRICS = 'SET_CUSTOM_EVENT_METRICS';
export function setCustomEventMetrics(payload: any) {
  return { type: SET_CUSTOM_EVENT_METRICS, payload };
}

export const SET_CUSTOM_EVENT_FILTERS = 'SET_CUSTOM_EVENT_FILTERS';
export function setCustomEventFilters(payload: any) {
  return { type: SET_CUSTOM_EVENT_FILTERS, payload };
}

export const RESET_CUSTOM_EVENTS = 'RESET_CUSTOM_EVENTS';
export function resetCustomEvents() {
  return { type: RESET_CUSTOM_EVENTS };
}
export const SET_PROJECT = 'SET_PROJECT';
export const setProjectId = (project: any) => ({
  type: SET_PROJECT,
  payload: project,
});

export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST';
export const setProjects = (projects: any) => ({
  type: SET_PROJECTS_LIST,
  payload: projects || [],
});

export const SET_PROJECTS_LOADING = 'SET_PROJECTS_LOADING';
export const setProjectsLoading = (loading: boolean) => ({
  type: SET_PROJECTS_LOADING,
  payload: loading,
});

export const SET_MS_TABLE = 'SET_MS_TABLE';
export const setMSTableAndProjectId = (table: any) => ({
  type: SET_MS_TABLE,
  payload: table,
});

export const SET_MS_TABLE_LIST = 'SET_MS_TABLE_LIST';
export const setMSTable = (tables: any) => ({
  type: SET_MS_TABLE_LIST,
  payload: tables,
});

export const SET_MS_TABLE_LOADING = 'SET_MS_TABLE_LOADING';
export const setMSTableLoading = (loading: boolean) => ({
  type: SET_MS_TABLE_LOADING,
  payload: loading,
});

export const SET_SHOW_LONG_RANGE_ALERT = 'SET_SHOW_LONG_RANGE_ALERT';
export const setShowLongRangeAlert = (show: boolean) => ({
  type: SET_SHOW_LONG_RANGE_ALERT,
  payload: show,
});

export const RESET_INSIGHTS = 'RESET_INSIGHTS';
export const resetInsights = () => ({ type: RESET_INSIGHTS });

export const RESET_FUNNELS = 'RESET_FUNNELS';
export const resetFunnels = () => ({ type: RESET_FUNNELS });

export const SET_GLOBAL_EVENT_METRICS = 'SET_GLOBAL_EVENT_METRICS';
export function setGlobalEventMetrics(payload: any) {
  return { type: SET_GLOBAL_EVENT_METRICS, payload };
}

export const TOGGLE_HIDE_METRIC = 'TOGGLE_HIDE_METRIC';
export function toggleHideMetric(payload: any) {
  return { type: TOGGLE_HIDE_METRIC, payload };
}

export const SET_METRIC_MEASUREMENTS = 'SET_METRIC_MEASUREMENTS';
export function setMetricMeasurements(payload: any) {
  return { type: SET_METRIC_MEASUREMENTS, payload };
}

export const SET_GLOBAL_METRIC_FILTERS = 'SET_GLOBAL_METRIC_FILTERS';
export function setGlobalMetricFilters(payload: any) {
  return { type: SET_GLOBAL_METRIC_FILTERS, payload };
}

// This is used when user selects the filters
export const SET_GLOBAL_FILTERS = 'SET_GLOBAL_FILTERS';
export function setGlobalFilters(payload: any) {
  return { type: SET_GLOBAL_FILTERS, payload };
}

export const SET_GLOBAL_BREAKDOWN = 'SET_GLOBAL_BREAKDOWN';
export function setGlobalBreakdown(payload: any) {
  return { type: SET_GLOBAL_BREAKDOWN, payload };
}

// This is the fetch all the fitlers available from the api.
export const FETCH_GLOBAL_FILTERS = 'FETCH_GLOBAL_FILTERS';
export function fetchAndSetGlobalFilters(payload: any) {
  return { type: FETCH_GLOBAL_FILTERS, payload };
}

export const FETCH_ALL_METRICS = 'FETCH_ALL_METRICS';
export function fetchAllMetrics(payload: any) {
  return { type: FETCH_ALL_METRICS, payload };
}

export const SET_ALL_METRICS = 'SET_ALL_METRICS';
export function setAllMetrics(payload: Array<TEvent | TCustomEvent>) {
  return { type: SET_ALL_METRICS, payload };
}

export const RESET_ALL_METRIC_STATE = 'RESET_ALL_METRIC_STATE';
export function resetAllMetrics() {
  return { type: RESET_ALL_METRIC_STATE };
}

export const SET_EXPLORE_CONTROLS = 'UPDATE_EXPLORE_CONTROLS';
export function setExploreControls(formData: QueryFormData) {
  return { type: SET_EXPLORE_CONTROLS, formData };
}

export const SET_FORM_DATA = 'UPDATE_FORM_DATA';
export function setFormData(formData: QueryFormData) {
  return { type: SET_FORM_DATA, formData };
}

export const UPDATE_CHART_TITLE = 'UPDATE_CHART_TITLE';
export function updateChartTitle(sliceName: string) {
  return { type: UPDATE_CHART_TITLE, sliceName };
}

export const SET_SAVE_ACTION = 'SET_SAVE_ACTION';
export function setSaveAction(saveAction: SaveActionType | null) {
  return { type: SET_SAVE_ACTION, saveAction };
}

export const CREATE_NEW_SLICE = 'CREATE_NEW_SLICE';
export function createNewSlice(
  can_add: boolean,
  can_download: boolean,
  can_overwrite: boolean,
  slice: Slice,
  form_data: QueryFormData,
) {
  return {
    type: CREATE_NEW_SLICE,
    can_add,
    can_download,
    can_overwrite,
    slice,
    form_data,
  };
}

export const SLICE_UPDATED = 'SLICE_UPDATED';
export function sliceUpdated(slice: Slice) {
  return { type: SLICE_UPDATED, slice };
}

export const SET_FORCE_QUERY = 'SET_FORCE_QUERY';
export function setForceQuery(force: boolean) {
  return {
    type: SET_FORCE_QUERY,
    force,
  };
}

export const exploreActions = {
  ...toastActions,
  fetchDatasourcesStarted,
  fetchDatasourcesSucceeded,
  toggleFaveStar,
  fetchFaveStar,
  saveFaveStar,
  setControlValue,
  setExploreControls,
  updateChartTitle,
  createNewSlice,
  sliceUpdated,
  setForceQuery,
};

export type ExploreActions = typeof exploreActions;
